<template>
  <div>
    <h1>Получение коэффициентов</h1>
    <div class="filter">
      <label class="filter--label">От</label>
      <Datepicker
        v-model="filter.date_from"
        locale="ru"
        :enableTimePicker="false"
        selectText="Выбрать"
        cancelText="Отмена"
        format="dd.MM.yyyy"
      />
      <label class="filter--label m-left">до</label>
      <Datepicker
        v-model="filter.date_to"
        locale="ru"
        :enableTimePicker="false"
        selectText="Выбрать"
        cancelText="Отмена"
        format="dd.MM.yyyy"
      />
      <a v-if="loader" class="btn-def btn-filter" href="javascript:void(0)">
        <i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>
        <i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>
        <i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>
        <span class="sr-only">Загрузка...</span>
      </a>
      <a v-else class="btn-def btn-filter" @click="filterOrders" href="javascript:void(0)">
        Получить
      </a>
    </div>
    <div class="info-panel">
      <Download  controller="/archive/order-closed-excel"
                :from="format_date_from" :to="format_date_to" style="float:right" />
      <label>Всего заказов:</label> {{orders.length}} |
    </div>
    <div class="table-row">
      <div class="table-th">Номер заказа</div>
      <div class="table-th">Машинное время (мин)</div>
      <div class="table-th">Время выполнения (мин)</div>
      <div class="table-th">Коэф.</div>
    </div>
    <div v-if="loader" style="text-align:center; padding:12px 0px">
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      <span class="sr-only">Загрузка...</span>
    </div>
    <template v-else>
    <div v-if="orders.length">
      <div class="table-row" v-for="(order, index) in orders" :key="index" >
        <div class="table-tr">{{ order.number }}</div>
        <div class="table-tr">{{ order.timeMachine }}</div>
        <div class="table-tr">{{ order.timeAll }}</div>
        <div class="table-tr">{{ order.timeKoef }}</div>
      </div>
    </div>
      <div v-else>
        <span>Настройте выборку по фильтру</span>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Download from '../components/download/cross-download.vue';

export default {
  components: {
    Datepicker,
    Download,
  },
  name: 'CalcKoef',
  data() {
    return {
      loader: false,
      orders: [],
      filter: {
        date_from: new Date(new Date().getFullYear(), new Date().getMonth() - 1),
        date_to: new Date(),
      },
    };
  },
  created() {
    this.getOrders();
  },
  computed: {
    format_date_from() {
      return `${this.filter.date_from.getMonth() + 1}/${this.filter.date_from.getDate()}/${this.filter.date_from.getFullYear()}`;
    },
    format_date_to() {
      return `${this.filter.date_to.getMonth() + 1}/${this.filter.date_to.getDate()}/${this.filter.date_to.getFullYear()}`;
    },
  },
  methods: {
    getOrders() {
      this.loader = true;
      const act = 'archive/order-closed'; // 12/12/2021
      axios.get(`${act}?from=${this.format_date_from}&to=${this.format_date_to}`)
        .then((res) => {
          this.orders = res.data.orders;
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loader = false;
        });
    },
    clearOrders() {
      this.orders = [];
    },
    filterOrders() {
      this.getOrders();
    },
  },
};
</script>

<style scoped>
.table-row{display: flex; flex-direction: row; color:#eee;}

.table-row:hover .table-tr{opacity: 0.9;}

.table-th, .table-tr{flex: 1; text-align:left; padding:10px;}
.table-tr{color: #000}

.table-th{background:#3e5977; font-size:14px; font-weight:bold; border-bottom: 1px solid #777;}
.table-tr{font-size:14px; border-bottom: 1px solid #777;}

.filter{display:flex; margin-bottom:12px;}
.btn-filter{padding:7px 10px; margin-left:10px;}
.filter--label{margin-right:12px; display:flex; align-items: center; font-weight:bold}
.filter--label.m-left{margin-left:12px;}
</style>
